import React, { Suspense } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { Container } from 'reactstrap'
import AdminFooter from '../components/Footers/AdminFooter'
import Sidebar from '../components/Sidebar/Sidebar'
import LoadingOverlay from 'react-loading-overlay'
import routes from '../routes.tsx'
import rightBarTopRoutes from '../rightBarTopRoutes'
import '../assets/css/spinner-aux.css'
import { withBetaUserEasterEgg } from '../components/HOC/withBetaUserEasterEgg'
import AdminNavbar from '../components/Navbars/AdminNavbar'
import { AuthContext } from '../hooks'
import { getLoginRoute } from '../routes'
import { SessionTimeout } from '../components/SessionTimeout'
import { LoadingBackground } from '../components/Loading'
import { Oval } from 'react-loader-spinner'

class Admin extends React.Component {
  static contextType = AuthContext

  componentDidUpdate(e) {
    document.documentElement.scrollTop = 0
    document.scrollingElement.scrollTop = 0
    this.refs.mainContent.scrollTop = 0
  }

  filterIsAdminLayout = (route) => route.layout === '/admin'

  filterBetaRoutes = (route) => (this.props.betaUser ? true : !route.betaUserOnly)

  getSwitchRoutes = (routes) =>
    routes
      .filter(this.filterBetaRoutes)
      .filter(this.filterIsAdminLayout)
      .map(({ layout, path, component }, key) => {
        if (path && component) {
          return <Route path={layout + path} component={component} key={key} />
        }
      })

  getSidebarRoutes = (routes) => routes.filter(this.filterBetaRoutes)

  getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (this.props.location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
        return routes[i].name
      }
    }
    return 'Brand'
  }
  render() {
    const authenticated = this.context?.authenticated
    if (authenticated === false) {
      const loginRoute = getLoginRoute()
      const loginPath = (loginRoute?.layout ?? '') + loginRoute?.path
      console.warn('redirecting unauthenticated user to login page')
      return <Redirect to={loginPath} />
    }

    return (
      <>
        <Sidebar
          {...this.props}
          routes={this.getSidebarRoutes(routes)}
          logo={{
            innerLink: '/admin/home',
            imgSrc: require('../assets/img/brand/logo-nova-branca.png'),
            imgAlt: '...'
          }}
          id="sidenav-main"
          position="fixed-left"
        />
        <main className="main-content" ref="mainContent">
          <AdminNavbar
            {...this.props}
            brandText={this.getBrandText(this.props.location.pathname)}
            rightBarTopRoutes={rightBarTopRoutes}
          />
          <Suspense
            fallback={
              <LoadingBackground lowIndex>
                <Oval />
              </LoadingBackground>
            }
          >
            <Switch>{this.getSwitchRoutes(routes)}</Switch>
          </Suspense>

          {/* <Container fluid>
            <AdminFooter />
          </Container> */}
        </main>

        <SessionTimeout cacheTime={30} />
        <LoadingOverlay active={global.spinnerActive} spinner text={global.spinnerMessage} />
      </>
    )
  }
}

export default withBetaUserEasterEgg(Admin, [
  'ArrowLeft',
  'ArrowRight',
  'ArrowLeft',
  'ArrowRight',
  ' ',
  'ArrowUp'
])
