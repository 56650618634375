import React, { useCallback } from 'react'
import { Col } from 'reactstrap'
import { IFieldSelect } from './types'
import { DateSelect } from '../DateSelect'
import moment from 'moment'
import styled from 'styled-components'

const InputText = styled.input`
  width: 100%;
  background: #f7f9fb;
  border-radius: 15px;
  border: 0px solid #dee5ef;
  padding: 5px 8px;

  &[disabled] {
    opacity: 0.5;
  }
`
export const FieldSelectDate = <T extends IFieldSelect<T>>({
  afterContent,
  className,
  disabled,
  id,
  label = 'Data *',
  onChange,
  selectedValue,
  size = { lg: 6, xl: 6 },
  required = true
}: IFieldSelect<T>) => {
  const selectedDate = useCallback(() => {
    if (!selectedValue && required) {
      console.log('!selectedValue && required', id)
      return moment()?.toDate()
    }

    if (!selectedValue && !required) {
      console.log('!selectedValue && !required', id)
      return undefined
    }

    return moment(selectedValue, 'DD/MM/YYYY').toDate()
  }, [id, required, selectedValue])

  return (
    <Col lg={size.lg} xl={size.xl} className={className}>
      {label && <label>{label}</label>}

      {disabled ? (
        <InputText type="text" disabled value={moment(selectedValue, 'DD/MM/YYYY').format('DD/MM/YYYY')} />
      ) : (
        <DateSelect className={className} id={id} onChange={onChange} selected={selectedDate()} />
      )}

      {!!afterContent && afterContent}
    </Col>
  )
}
